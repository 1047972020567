<template>
  <div v-if="showAdvantage">
    <div :class="`home-advantage ${newTheme !== 'black' ? 'white' : ''}`">
      <h2 class="home-advantage-title">{{ $t('index.compony_advantage') }}</h2>
      <div class="home-advantage-wrap">
        <div @mouseover="changeIndex(0)"  class="home-advantage-item link-item" :class="{ active: curIndex === 0 }">
          <nuxt-link :to="$i18n.path('global-strategy')" class="advantage-main">
            <h3 class="h3">{{ $t('index.advantage_h2_1') }}</h3>
            <p class="p" v-html="$t('index.advantage_text_1')"></p>
            <section class="advantage-item-footer">
              <img src="~assets/img/index/advantage-img1.svg" alt="" :class="['advantage-item-img', {'fa-direction': isRtl }]"  loading="lazy"/>
              <img src="~assets/img/index/advantage-arrow.svg" alt="" :class="['advantage-item-arrow', {'advantage-item-arrow-show' : curIndex === 0, 'fa-direction':isRtl }]"/>
            </section>
          </nuxt-link>
        </div>
        <Gap :width="itemGap" />
        <div @mouseover="changeIndex(1)" class="home-advantage-item" :class="{ active: curIndex === 1 }">
          <div class="advantage-main">
            <h3 class="h3">{{ $t('index.advantage_h2_2') }}</h3>
            <p class="p" v-html="$t('index.advantage_text_2')"></p>
            <section class="advantage-item-footer">
              <img src="~assets/img/index/advantage-img2.png" alt="" :class="['advantage-item-img', {'fa-direction': isRtl }]" loading="lazy"/>
            </section>
          </div>
        </div>
        <Gap :width="itemGap" />
        <div @mouseover="changeIndex(2)" class="home-advantage-item" :class="{ active: curIndex === 2 }">
          <div class="advantage-main">
            <h3 class="h3">{{ $t('index.advantage_h2_3') }}</h3>
            <section class="p">
              <p class="p" v-html="$t('index.advantage_text_3')"></p>
              <div class="protect-fund">
                <a class="fund" :href="$t('protect.wallet_bit_link')" rel="nofollow" target="_blank">
                  <div class="wallet">
                    {{ $t('index.wallet1') }}
                  </div>
                  <p class="wallet-bit">{{ $t('index.wallet_bit') }}</p>
                  <div :class="['fund_img', {'fa-direction': isRtl }]" />
                </a>
                <a
                  class="fund"
                  style="display:none"
                  href="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7?a=0x2ba7DB955DF5b32fBd9ed3Ef475EF2746745707C"
                  target="_blank"
                >
                  <div class="wallet">
                    <p>{{ $t('index.wallet2') }}</p>
                    <div :class="['fund_img', {'fa-direction': isRtl }]" />
                  </div>
                  <p>{{ $t('index.wallet_usdt') }}</p>
                </a>
              </div>
            </section>
            <section class="advantage-item-footer">
              <img src="~assets/img/index/advantage-img3.svg" alt="" :class="['advantage-item-img', {'fa-direction': isRtl }]" loading="lazy"/>
            </section>
          </div>
        </div>
        <Gap :width="itemGap" />
        <div @mouseover="changeIndex(3)" class="home-advantage-item" :class="{ active: curIndex === 3 }">
          <div class="advantage-main">
            <h3 class="h3">{{ $t('index.advantage_h2_4') }}</h3>
            <p class="p" v-html="$t('index.advantage_text_4')"></p>
            <section class="advantage-item-footer">
              <img src="~assets/img/index/advantage-img4.svg" alt="" :class="['advantage-item-img', {'fa-direction': isRtl }]" loading="lazy"/>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Gap from "~/components/gap"
import { isClient } from '~/utils/envHelper.js';
import { isRtlLanguage } from '~/utils/locale.js';

export default {
  components:{
    Gap
  },
  data() {
    return {
      showAdvantage: true,
      // transformShow: false, // 为了动画效果
      curIndex: 0,
      img1Dark: require('@/assets/img/new_index/img1_big_black.png'),
      img1White: require('@/assets/img/new_index/img1_big_white.png'),
      img2Dark: require('@/assets/img/new_index/img2_big_black.png'),
      img2White: require('@/assets/img/new_index/img2_big_white.png'),
      img3Dark: require('@/assets/img/new_index/img3_big_black.png'),
      img3White: require('@/assets/img/new_index/img3_big_white.png'),
      img4Dark: require('@/assets/img/new_index/img4_big_black.png'),
      img4White: require('@/assets/img/new_index/img4_big_white.png'),
    };
  },
  computed: {
    ...mapState(['newTheme', 'locale']),
    isRtl() {
      return isRtlLanguage(this.$route.path)
    },
    itemGap() {
      return isClient && window.innerWidth > 1200 ? 24 :16;
    }
  },
  watch: {
    locale() {
      this.curIndex = 0;
    },
    newTheme(val) {
      this.changeTheme(val)
    }
  },
  methods: {
    // scorllShowFn() {
    //   let rect = this.$refs['elTransformShow'].getBoundingClientRect();
    //   let elemTop = rect.top + 120; // 此数值控制显示的位置，看UI要求吧
    //   let elemBottom = rect.bottom;
    //   this.transformShow = elemTop < window.innerHeight && elemBottom >= 0;
    // },
    goTo(path){
      this.$router.push(this.$i18n.path(path));
    },
    changeIndex(index) {
      this.curIndex = index;
    },
    changeTheme(themeMode){
      this.showAdvantage = false
      this.$nextTick(() => {
        this.showAdvantage = true
      });
    }
  },
  created() {
    this.changeTheme(this.newTheme);
  },
  beforeDestroy() {
    // if (process.browser) {
    //   window.removeEventListener('scroll', this.scorllShowFn);
    // }
  }
};
</script>

<style lang="less" scoped>
// 公司优点
.home-advantage {
  width: 1200px;
  margin: 0 auto;
  padding: 100px 0 100px;
  box-sizing: border-box;
  .home-advantage-title {
    margin-bottom: 60px;
    font-size: 40px;
    color: @black-text-primary-V2;
    .bold-font();
  }
  .home-advantage-wrap {
    .flex();
    .flex-main-justify();

    .home-advantage-item {
      width: 234px;
      height: 388px;
      background: @black-fill-card1-V2;
      overflow: hidden;
      border-radius: 24px;
      box-sizing: border-box;
      .flex();
      transition: all 0.1s;
      padding: 31px 24px;
      .advantage-main {
        position: relative;
        width: 360px;
        height: 100%;
        box-sizing: border-box;
        color: @black-text-primary-V2;
        display: flex;
        flex-direction: column;

        .h3 {
          font-size: 32px;
          .medium-font();
          margin-bottom: 16px;
          display: block;
          flex-grow: 1;
        }
        .p {
          width: 360px;
          font-size: 16px;
          line-height: 24px;
          display: none;
        }
        .protect-fund {
          margin-top: 16px;
          display: none;
          .fund {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            padding: 0 24px;
            background: @black-btn-secondary-default-V2;
            border-radius: 16px;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;
            &:hover {
              background: @black-btn-secondary-hover-V2;
            }
            .wallet {
              .medium-font();
              color: @black-text-secondary-V2;
              margin-right: 16px;
            }
            .wallet-bit {
              display: inline-block;
              flex-grow: 1;
              .bold-font();
              font-size: 18px;
              color: @black-text-primary-V2;
            }
            .fund_img {
                width: 20px;
                height: 20px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url(~assets/img/new_index/cross-arrow.svg);
              }
          }
        }
        .jianto-advantage {
          position: absolute;
          left: 32px;
          bottom: 32px;
          width: 16px;
          cursor: pointer;
        }
        .advantage_default {
          position: absolute;
          bottom: 48px;
          right: -30px;
          width: 150px;
          opacity: 0.7;
        }
      }

      &.link-item{
        cursor: pointer;
        position: relative;
        .right-arrow{
          position: absolute;
          right: 52px;
          top: 57px;
          width: 24px;
          display: none;
        }
        &.active {
          .right-arrow{
            display: block;
          }
        }
      }
      &.active {
        width: 424px;
        padding:31px 32px;
        .advantage-main {
          .h3{
            flex-grow: 0;
          }
          .p{
            flex-grow: 1;
          }
          .p,
          .protect-fund {
            display: block;
          }
          .jianto-advantage{
            display: none;
          }
          .advantage_default {
            opacity: 1;
            right: 48px;
          }
        }
      }
      .advantage-item-footer{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .advantage-item-img{
          width: 120px;
          height: 120px;
        }
        .advantage-item-arrow{
          width: 48px;
          height: 48px;
          display: none;
        }
        .advantage-item-arrow-show{
          display: block;
        }
      }
    }
    // .home-advantage-item + .home-advantage-item{
    //     margin-left: 24px;
    // }
  }
}

.white {
  &.home-advantage {
    .home-advantage-title,
    .home-advantage-wrap .home-advantage-item .advantage-main {
      color: #000;
    }
    .home-advantage-wrap .home-advantage-item{
      background: @white-module-color;
    }
  }
}
@media screen and (max-width: 1200px) {
  .home-advantage{
    width: 940px;
  }
  .home-advantage .home-advantage-wrap {
    margin-left: 16px;
  }
  .home-advantage .home-advantage-wrap .home-advantage-item{
    width: 190px;
    &.active{
      width: 320px;
    }
  }
  .home-advantage .home-advantage-wrap .home-advantage-item .advantage-main .h3{
    font-size: 28px;
  }
  .home-advantage .home-advantage-wrap .home-advantage-item .advantage-main{
    width: 256px;
    .p{
      width: 256px;
    }
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1199px){
  .home-advantage {
    width: 960px;
  }
}
@media screen and (max-width: 1019px) {
  .home-advantage {
    width: 100%;
    .px2rem(padding-bottom, 280);
    .home-advantage-wrap {
      width: 100%;
      justify-content: center;
      flex-flow: row wrap;
      .px2rem(padding-left, 40);
      .px2rem(padding-right, 40);

      .home-advantage-item {
        min-width: 46%;
        width: auto;
        margin-bottom: 40px;
        margin-right: 0;
      }
    }
  }
}
</style>
