<template>
  <section class="home-swiper-box-h5">
    <div
      v-swiper:mySwiper="{ ...swiperOption, loop: device === 'web' ? bannerImg.length > 4 : bannerImg.length > 1 }"
      v-if="bannerImg.length"
      class="swiperWrap"
      @click-slide="clickSlide"
    >
      <div class="swiper-wrapper" style="display: flex">
        <div v-for="(item, index) in bannerImg" :key="index" class="swiper-slide" v-ui-test="'banner-item'">
          <!-- @todo 修改内联样式-->
          <a :href="bannerLink[index]" style="pointer-events: none; display: block">
            <img :src="item" :key="item" alt="" />
          </a>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-bullets" v-if="device === 'h5'" style="text-align: center; bottom: 0"></div>
      <div class="left-btn opt" v-if="bannerImg.length > 4" @click.stop="slide">
        <div class="btn"></div>
      </div>
      <div class="right-btn opt" v-if="bannerImg.length > 4" @click.stop="slide">
        <div class="btn"></div>
      </div>
    </div>
  </section>
</template>

<script>
let _this = this;

import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '~/plugins/firebase.js';
import {  mapState } from 'vuex';
import { PC_BannerClick, PC_BannerExposure } from '~/utils/sensorsEvent';
export default {
  name: 'Index-Banner',
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        // scrollbarHide: true,
        autoplay: {
          delay: 4000, // 圖片停留時間4秒
          stopOnLastSlide: false,
          disableOnInteraction: false // user中斷輪播後會自重啟autoplay
        },
        navigation: {
          nextEl: '.right-btn',
          prevEl: '.left-btn'
        },
        speed: 500, //輪播速度
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          clickable: true,
          renderCustom: function (swiper, current, total) {
            if (this.device === 'web') {
              return '';
            } else {
              //  @todo 修改内联样式
              let _html = '';
              const activeColor = _this.newTheme == 'white' ? '#000' : '#fff';
              const defaultColor = _this.newTheme == 'white' ? '#ADAFB8' : '#4D4F5A';
              for (let i = 1; i <= total; i++) {
                if (current == i) {
                  _html += `<div style="width:15px;height:6px;border-radius:4px;background-color:${activeColor};margin-left:6px;margin-right:6px"></div>`;
                } else {
                  _html += `<div style="width:6px;height:6px;border-radius:50%;background-color:${defaultColor};margin-left:6px;margin-right:6px"></div>`;
                }
              }
              return (
                '<div style="justify-content:center;width:100%;align-items:center;display:flex;">' + _html + '</div>'
              );
            }
          }
        },
        on: {
          slideChange() {
            const index = this.activeIndex % _this.bannerLink.length;
            _this.bannerExposure(_this.bannerTitle[index], index+1);
          },
          click(e) {
            if (!_this.isSlide) {
              let num = 0;
              if (_this.bannerLink && _this.bannerLink.length > 0) {
                num = this.clickedIndex % _this.bannerLink.length;
                if (_this.bannerLink[num].indexOf('topic/agent-introduction-topic') != -1) {
                  _this.goto('topic/agent-introduction-topic', _this.bannerTitle[num]);
                } else if (_this.bannerLink[num].indexOf('competition') != -1) {
                  _this.goto('competition', _this.bannerTitle[num]);
                } else {
                  _this.openLink(_this.bannerLink[num], _this.bannerTitle[num]);
                  _this.bannerClick(_this.bannerTitle[num], num+1, _this.bannerLink[num]);
                }
              }
            } else _this.isSlide = false;
          }
        }
      },
      isSlide: false,
      analytics: {}
    };
  },
  props: {
    device: {
      type: String,
      default: 'web'
    },
    bannerImg: {
      type: Array,
      default: () => []
    },
    bannerLink: {
      type: Array,
      default: () => []
    },
    bannerTitle: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['newTheme']),
  },
  methods: {
    bannerClick(banner_name, banner_position, banner_value) {
      PC_BannerClick(banner_name, banner_position, banner_value);
    },
    bannerExposure(banner_name, banner_position) {
      PC_BannerExposure(banner_name, banner_position);
    },
    /**
     * 点击banner业务处理
     * @param index
     * @param reallyIndex 下标数
     */
    clickSlide(index, reallyIndex) {
      let _this = this;
      this.bannerLink.forEach(function (item, index, array) {
        if (reallyIndex == index && item != '') {
          if (this.device === 'web') {
            _this.openLink(_this.bannerLink[index], _this.bannerTitle[index]);
            _this.bannerClick(_this.bannerTitle[index], index+1, _this.bannerLink[index]);
          } else {
            if (_this.bannerLink[index].indexOf('topic/agent-introduction-topic') != -1) {
              _this.goto('topic/agent-introduction-topic', _this.bannerTitle[index]);
            } else if (_this.bannerLink[index].indexOf('competition?fromAPP=Y') != -1) {
              _this.goto('competition?fromAPP=Y', _this.bannerTitle[index]);
            } else if (_this.bannerLink[index].indexOf('competition') != -1) {
              _this.goto('competition', _this.bannerTitle[index]);
            } else {
              _this.openLink(_this.bannerLink[index], _this.bannerTitle[index]);
              _this.bannerClick(_this.bannerTitle[index], index+1, _this.bannerLink[index]);
            }
          }
        }
      });
    },
    goto(path, title) {
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: this.device
      });
      this.$router.push(this.$i18n.path(path));
    },
    openLink(hrefStr, title) {
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: this.device
      });
      window.open(hrefStr);
    },
    slide(event) {
      this.isSlide = true;
    }
  },
  mounted() {
    _this = this;
    // Initialize Firebase
    this.analytics = getAnalytics(firebaseApp);
  },
  watch: {
    swiperOption(val) {
      console.log('swiperOption:',val)
    },
    bannerImg(val, oldVal) {
      if(val?.length !== oldVal?.length){
        this.$nextTick(() => {
          this.$refs.mySwiper?.swiper?.update();
        });
      };
      if (val.length > 0) {
        if ((this.device === 'h5'&&val.length==1) || (this.device === 'web'&&val.length<=4)) {
          val.forEach((item,index) => {
            this.bannerExposure(this.bannerTitle[index], index+1);
          });
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.clearMarginAndPadding{
  margin: 0;
  padding: 0;
}
* {
  .clearMarginAndPadding();
}
.initHtml{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
}

html,
body {
  .initHtml();
}

html {
  font-size: getWidth1920(20);
}

.swiperWrap {
  position: relative;

  .swiper-slide {
    width: 288px;
    height: 150px;
    cursor: pointer;

    div {
      line-height: 0;
    }
    img {
      width: 288px;
      height: 150px;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .opt {
    position: absolute;
    top: 0;
    height: 150px;
    width: 48px;
    z-index: 999;

    .btn {
      width: 32px;
      margin: 56px 8px;
      height: 32px;
    }
  }

  .left-btn {
    left: 0;

    .btn {
      background: url('~assets/img/banner/icon_left.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .right-btn {
    right: 0;

    .btn {
      background: url('~assets/img/banner/icon_right.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .left-btn:hover {
    .btn {
      background: url('~assets/img/banner/icon_left_h.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .right-btn:hover {
    .btn {
      background: url('~assets/img/banner/icon_right_h.svg') no-repeat;
      background-size: 100% 100%;
    }
  }
}

@media screen and (min-width: 1020px) and (max-width: 1199px) {
.swiperWrap {
  width: 940px;
  margin-left: auto;
  margin-right: auto;
}
}

@media screen and (max-width: 1019px) {
  * {
    .clearMarginAndPadding();
  }

  html,
  body {
    .initHtml();
  }

  .home-swiper-box-h5 {
    .swiperWrap {
      margin-left: 16px;
      margin-right: 16px;
      padding-bottom: 15px;
      box-sizing: border-box;
      .swiper-slide {
        cursor: pointer;
        box-sizing: border-box;
        width: 259px;
        height: 134px;
        border-radius: 18px;
        img {
          box-sizing: border-box;
          width: 259px;
          height: 134px;
          border-radius: 18px;
        }
      }
    }

    .opt {
      display: none;
    }

    .swiper-pagination {
      display: block;
    }
  }
}
</style>
