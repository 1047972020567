<template>
  <div :class="['official-item-block', { 'link-card': link }]" @click="onOpenWindow">
    <div ref="officialItemImg" :src="img" alt="" class="official-item-img" ></div>
    <section class="official-item-value" dir="ltr">{{ value }}</section>
    <section class="official-item-desc">{{ desc }}</section>
  </div>
</template>

<script>
// import lottie from "lottie-web";

export default {
  props: ['img', 'value', 'desc', 'link','isPlay'],
  data() {
    return {
      animation:null
    };
  },
  watch:{
    isPlay(val){
      if(val){
        this.animation.play();
      }else{
        this.animation.stop();
      }
    }
  },
  methods: {
    onOpenWindow() {
      if (this.link) {
        var newWin = window.open();
        newWin.opener = null;
        newWin.location = this.link;
        newWin.target = '_blank';
      }
    }
  },
  mounted(){
		import('lottie-web').then(lottie => {
			this.animation = lottie.default.loadAnimation({
				container: this.$refs.officialItemImg,
				renderer: "svg",
				loop: false,
				autoplay: false,
				animationData:this.img,
				setSpeed:0.9
			});
		})
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.official-item-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 316px;
  height: 329px;
  .official-item-img {
    width: 120px;
    height: 120px;
    margin-bottom: 40px;
  }
  .official-item-value {
    font-size: 32px;
    .bold-font();
    color: @black-text-primary-V2;
    margin-bottom: 16px;
  }
  .official-item-desc {
    color: @black-text-secondary-V2;
  }
}
.link-card {
  cursor: pointer;
  &:hover {
    .official-item-value {
      color: @black-text-brand-V2;
    }
    .official-item-desc {
      color: @black-text-brand-V2;
    }
  }
}
</style>
