<template>
  <div>
    <!-- 中文和繁体语言下轮播切换 -->
    <section
      v-if="showBanner"
      class="zh-carousel"
      style="position: relative"
      :class="[theme, `theme-${theme} home-box`]"
      @mouseenter="autoplay = false"
      @mouseleave="autoplay = true"
    >
      <div class="zh-main-wrap" :class="locale + curIndex">
        <div v-if="showCarousel" class="left-arrow" @click="arrowClick('left')">
          <template>
            <img
              :class="['icon-img arrow-default',{'fa-direction':isRtl}]"
              :src="`/assets/images/new_index/banner/icon_L_hui${darkTheme ? '' : '_white'}.png`"
              alt=""
            />
            <img
              :class="['icon-img arrow-default-hover', {'fa-direction':isRtl}]"
              :src="`/assets/images/new_index/banner/icon_L_active${darkTheme ? '' : '_white'}.png`"
              alt=""
            />
          </template>
        </div>
        <el-carousel
          ref="cardShow"
          :indicator-position="showIndicator"
          arrow="never"
          :interval="6000"
          v-if="isClient"
          :autoplay="autoplay"
          :height="bannerHeight"
          @change="
            (curIndex, prevIndex) => {
              this.curIndex = curIndex;
            }
          "
        >
          <el-carousel-item v-for="(banner, index) in bannerList" :key="`carousel-item-${index + 1}}`">
            <div
              class="item-wrap"
              :style="banner.ext?.homeAdvantage ? 'padding-top: 42px' : ''"
              :key="`carousel-item-${index}`"
              @click="bannerClick(banner.title, index + 1, banner.MainImgLink)"
            >
              <div class="img-box">
                <img
                  class="banner-img"
                  :src="banner.theme.dark.MainImgPath"
                  :key="`carousel-img-${index + 1}`"
                  alt=""
                />
              </div>
              <section class="banner-text-row">
                <div class="left-wrap">
                  <template>
                    <h1 class="banner-title" v-html="banner.title"></h1>
                    <div class="banner-desc" v-html="banner.description"></div>
                    <a
                      target="_blank"
                      v-if="banner.ext?.type === 'linkButton'"
                      :class="banner.ext?.linkClass"
                      :href="banner.ext.link"
                    >
                      {{ banner.ext.linkLabel }}
                      <div class="link-btn-arrow" />
                    </a
                    >
                  </template>
                  <div class="gift-main-wrap" @click="goToActivity()">
                    <img class="gift-img" src="~assets/img/new_index/gift.png" alt="WEEX">
                    <div class="slogan-wrap" v-html="$t('new_index.adv.slogan')"></div>
                  </div>
                  <div ref="linkRef">
                    <BannerLink @showQR="showQR" @hideQR="hideQR"></BannerLink>
                  </div>
                  <div
                    v-if="banner.ext?.homeAdvantage"
                    class="home-advantage-new"
                    :class="{ en: ['en', 'vi'].includes(locale) }"
                  >
                    <div class="item" v-for="(item, index) in banner.ext?.homeAdvantage" :key="`adv-${index + 1}`">
                      <img class="img" :src="item.iconImgPath" alt />
                      <div class="title">{{ $t(item.titleKey) }}</div>
                      <div class="sub-tit">{{ $t(item.subTitleKey) }}</div>
                    </div>
                  </div>
                </div>
              </section>
              <a v-if="banner.MainImgLink" class="right-main-wrap" @click="gotoByUrl(banner.MainImgLink)" title=""></a>
            </div>
          </el-carousel-item>
        </el-carousel>
        <template v-else>
          <div>
            <div
              class="item-wrap"
              :style="bannerList[0]?.ext?.homeAdvantage ? 'padding-top: 42px' : ''"
              :key="`carousel-item-${index}`"
              @click="bannerClick(bannerList[0]?.title, index + 1, bannerList[0]?.MainImgLink)"
            >
              <div class="img-box">
                <img
                  class="banner-img"
                  :src="bannerList[0]?.theme.dark.MainImgPath"
                  :key="`carousel-img-${index + 1}`"
                  alt=""
                />
              </div>
              <section class="banner-text-row">
                <div class="left-wrap">
                  <template>
                    <h1 class="banner-title" v-html="bannerList[0]?.title"></h1>
                    <div class="banner-desc" v-html="bannerList[0]?.description"></div>
                    <a
                      target="_blank"
                      v-if="bannerList[0]?.ext?.type === 'linkButton'"
                      :class="bannerList[0]?.ext?.linkClass"
                      :href="bannerList[0]?.ext.link"
                    >
                      {{ bannerList[0]?.ext.linkLabel }}</a
                    >
                  </template>
                  <div class="gift-main-wrap" @click="goToActivity()">
                    <img class="gift-img" src="~assets/img/new_index/gift.png" alt="WEEX">
                    <div class="slogan-wrap" v-html="$t('new_index.adv.slogan')"></div>
                  </div>
                  <div ref="linkRef">
                    <BannerLink @showQR="showQR" @hideQR="hideQR"></BannerLink>
                  </div>
                  <div
                    v-if="bannerList[0]?.ext?.homeAdvantage"
                    class="home-advantage-new"
                    :class="{ en: ['en', 'vi'].includes(locale) }"
                  >
                    <div class="item" v-for="(item, index) in bannerList[0]?.ext?.homeAdvantage" :key="`adv-${index + 1}`">
                      <img class="img" :src="item.iconImgPath" alt />
                      <div class="title">{{ $t(item.titleKey) }}</div>
                      <div class="sub-tit">{{ $t(item.subTitleKey) }}</div>
                    </div>
                  </div>
                </div>
              </section>
              <a v-if="bannerList[0]?.MainImgLink" class="right-main-wrap" @click="gotoByUrl(bannerList[0]?.MainImgLink)" title=""></a>
            </div>
          </div>
        </template>
        <div v-if="showCarousel" class="right-arrow" @click="arrowClick('right')">
          <template>
            <img
              :class="['icon-img arrow-default']"
              :src="`/assets/images/new_index/banner/icon_R_hui${darkTheme ? '' : '_white'}.png`"
              alt=""
            />
            <img
              :class="['icon-img arrow-default-hover']"
              :src="`/assets/images/new_index/banner/icon_R_active${darkTheme ? '' : '_white'}.png`"
              alt=""
            />
          </template>
        </div>
        <!-- 下载二维码 -->
        <!-- 因轮播框父级的overflow：hidden会截取二维码，故提取到外层-->
        <div
          class="qr download"
          :class="locale"
          ref="qrCodeRef"
          @mouseover="showQR($event.target, true)"
          @mouseleave="hideQR"
        >
          <div class="img-container">
            <div class="qr-code">
              <download-qr :shareUrl="shareUrl" class="qr-code-box" :lang="locale" :size="100"> </download-qr>
            </div>
            <!-- <div class="logo-qr">
            <img src="~/assets/img/new_index/logo-qr.svg" />
          </div> -->
          </div>
          <div class="tit">{{ $t('new_index.header.download_app_tit2') }}</div>
          <div class="more-download" @click="goPath('download')">
            {{ $t('new_index.header.download_more') }}
          </div>
        </div>
      </div>
    </section>
    <section v-if="showBanner" class="introduce">
      <div class="introduce_cont">
        <template v-if="isClient">
          <div v-if="h5BannerImgList.length" class="introduce_top">
            <div>
              <MainBanner @updateSlideIndex="idx => (this.slideIndex = idx)" :bannerImg="h5BannerImgList" />
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="h5BannerImgList.length" class="introduce_top">
            <img :src="h5BannerImgList[0]?.imgUrl"/>
          </div>
        </template>
        <div v-if="showH5DefaultBanner" class="introduce_top">
          <img :src="bgImg" />
        </div>
        <div class="introduce_bottom">
          <template v-if="isClient">
            <div v-if="h5BannerImgList.length" class="title">
              <div v-html="mainTitle"></div>
              <div class="title2" v-html="subTitle"></div>
            </div>
          </template>
          <template v-else>
            <div v-if="h5BannerImgList.length" class="title">
              <div v-html="h5BannerImgList[0]?.mainTitle"></div>
              <div class="title2" v-html="h5BannerImgList[0]?.subTitle"></div>
            </div>
          </template>
          <div v-if="showH5DefaultBanner" class="title">
            <div v-if="locale == 'zh-CN' || locale == 'zh-TW'">{{ $t('new_index.adv.title1') }}</div>
            <div v-if="locale == 'en' || locale == 'ko' || locale == 'vi'">{{ $t('new_index.adv.title1') }}</div>
            <p class="title2">{{ $t('new_index.adv.title2') }}</p>
          </div>
          <div v-if="showH5DefaultBanner && (locale == 'zh-CN' || locale == 'zh-TW')" class="sub-tit">
            <p>{{ $t('new_index.adv.sub_title_wu') }}</p>
          </div>
          <div class="h5-gift-main-wrap" @click="goToActivity()">
            <img class="gift-img" src="~assets/img/new_index/gift.png" alt="WEEX">
            <div class="slogan-wrap" v-html="$t('new_index.adv.slogan')"></div>
          </div>
          <div class="login_register_box">
            <div class="new_Register_btn" @click="gotoRegister(registerName)">
              {{ $t('new_index.sign_up_bonus') }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PC_BannerClick } from '~/utils/sensorsEvent';
import config from '~/config/bannerConfig.json';
import { getMainBanner } from '~/server/common/index.js';
import MainBanner from "~/components/indexH5/MainBanner.vue";
import languageMapping from '~/config/languageMapping';
import Gap from "~/components/gap";
import {isClient} from "~/utils/envHelper";
import { enabledLanguageMapping, isRtlLanguage } from '~/utils/locale';

export default {
  props: ['theme', 'mainBannerList'],
  components: {
    MainBanner,
    BannerLink: () => import('./BannerLink.vue'),
    'download-qr': () => import('~/components/common/DownloadQr.vue'),
    Gap
  },
  data() {
    return {
      deviceType:'web',
      bannerHeight:'594px',
      qrLeft: 0,
      qrTop: 0,
      qrOverSelf: false,
      qrTimer: null,
      regCode: '',
      curIndex: 0,
      autoplay: true,
      showCarousel: false,
      darkTheme: this.theme === 'black',
      bannerList: [],
      h5BannerImgList: [],
      showBanner: true,
      showH5DefaultBanner: false,
      showIndicator: 'none', // 輪播下方切換是否展示
      lanList: enabledLanguageMapping.map(item => {
        return {
          key: item.language,
          num: item.langCode
        }
      }),
      // lanList: [
      //   {
      //     key: 'en',
      //     num: 0
      //   },
      //   {
      //     key: 'ko',
      //     num: 3
      //   },
      //   {
      //     key: 'zh-TW',
      //     num: 5
      //   },
      //   {
      //     key: 'zh-CN',
      //     num: 1
      //   },
      //   {
      //     key: 'vi',
      //     num: 4
      //   }
      // ],
      registerName: '',
      slideIndex: 0,
      registerErr: '',
      visibleEmailError: false,
      shareUrl: '',
      isClient: false,
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapGetters(['isLogin', 'getLang']),
    newTheme() {
      return this.$store.state.theme;
    },
    mainTitle() {
      if (!this.h5BannerImgList[this.slideIndex]) {
        return '';
      }
      return this.h5BannerImgList[this.slideIndex].mainTitle;
    },
    subTitle() {
      if (!this.h5BannerImgList[this.slideIndex]) {
        return '';
      }
      return this.h5BannerImgList[this.slideIndex].subTitle;
    },
    bgImg() {
      const theme = this.newTheme == 'white' ? 'light' : 'dark';
      return require(`@/assets/img/new_index/bg_home_${theme}.png`);
    },
    isRtl() {
      return isRtlLanguage(this.$route.path)
    }
  },
  created() {
    this.getBannerContent();
  },
  mounted() {
    this.isClient = isClient;
    this.shareUrl = location.origin + this.$i18n.path('download')
    this.switchScreen();
    window.addEventListener("resize",()=>{
      this.switchScreen();
    })
  },
  methods: {
    switchScreen(){
      if (window.innerWidth >= 1200){
        this.deviceType = 'web';
        this.bannerHeight = '594px';
      }else if (window.innerWidth >= 1020 && window.innerWidth<1200){
        this.deviceType = 'pad';
        this.bannerHeight = '448px';
      }else{
        this.bannerHeight = '448px';
        this.deviceType = 'h5';
      }
    },
    gotoRegister(rname) {
      let query = {};
      if (rname !== '' && rname !== undefined) {
        query.rname = rname;
      }
      this.$router.push({
        path: this.$i18n.path('register'),
        query
      });
    },
    goToActivity() {
      const path={
        'en':'https://www.weex.com/newbieActivities/?id=520&type=BEGINNER_TASK',
        'zh-CN':'https://www.weex.com/zh-CN/newbieActivities/?id=520&type=BEGINNER_TASK',
        'zh-TW':'https://www.weex.com/zh-TW/newbieActivities/?id=520&type=BEGINNER_TASK',
        'ko':'https://www.weex.com/newbieActivities/?id=520&type=BEGINNER_TASK',
        'vi':'https://www.weex.com/newbieActivities/?id=520&type=BEGINNER_TASK',
      }
      if(path[this.$i18n.locale]){
        window.open(path[this.$i18n.locale]);
      }else{
        window.open(path['en']);
      }
    },
    goToDownload(type) {
      // let path = 'support/jumpdl';
      // if (type) {
      //   path = `${path}?${type}`;
      // }
      this.$router.push('wxt');
    },
    // @note 邮箱有大写进行提示
    onValidEmail() {
      this.visibleEmailError = false;
      if (this.registerName && this.registerName.indexOf('@') > -1) {
        if (/[A-Z]+/.test(this.registerName)) {
          // @note 如果有大写字母则提示区分大小写
          this.registerErr = this.$i18n.t('new_index.adv.email_hint_letter');
          this.visibleEmailError = true;
        }
      }
    },
    setBannerInfo(data) {
      this.showCarousel = data?.carousel;
      this.showCarousel ? (this.showIndicator = '') : (this.showIndicator = 'none');
      this.bannerList = data?.banners;
      this.showBanner = data?.banners?.length > 0;
    },
    /**
     * 获取首页banner信息
     */
    getBannerContent() {
      const lanInfo = (this.lanList || []).find(item => item.key === this.$i18n.locale)
      const mainBannerList = this.mainBannerList || [];
      const showBannerList = mainBannerList
        .filter(item => item.languageId === lanInfo?.num)
        .filter(item => item.status === 1)
        .filter(item => (item.removalTime ? item.removalTime > new Date().getTime() : true));

      // if (this.isWeb) {
        if (showBannerList.length) {
          // const result = current
          const result = {
            locale: 'en',
            carousel: showBannerList.length > 1,
            banners: showBannerList?.map((item, idx) => {
              return {
                id: idx + 1 + '',
                title: item.mainTitle ?? '',
                description: item.subtitleTitle ?? '',
                ext: {
                  type: 'tgButton',
                  link: 'https://t.me/WeexGlobal_Group',
                  linkTitle: '',
                  linkLabel: 'Join WEEX community, get bonus'
                },
                MainImgLink: item.webJumpLink,
                theme: {
                  light: {
                    MainImgPath: item.webPosterWhiteUrl
                  },
                  dark: {
                    MainImgPath: item.webPosterDarkUrl
                  }
                },
                enable: item.status === 1,
                removalTime: item.endTime
              };
            })
          };
          this.setBannerInfo(result);
        }else{
          // 如果没哟可用的配置信息则使用默认配置
          const defaultBannerInfo = config.find(item => item.locale === this.$i18n.locale);
          // 避免新增语言未配置banner报错
          if(!defaultBannerInfo) return;
          defaultBannerInfo.banners = defaultBannerInfo.banners.filter(item => {
            // 判断轮播enable是否显示和是否已经下架。
            return item.enable && new Date(item.removalTime).getTime() > new Date().getTime();
          });
          defaultBannerInfo.carousel = defaultBannerInfo.banners?.length > 1;
          this.setBannerInfo(defaultBannerInfo);
        }


      // } else {
        this.showH5DefaultBanner = false;
        const h5BannderList = showBannerList.map(item => {
          const postUrl = this.newTheme === 'black' ? item.h5PosterDarkUrl : item.h5PosterWhiteUrl;
          return {
            imgUrl: postUrl,
            jumpLink: item.h5JumpLink,
            jumpType: item.jumpType,
            mainTitle: item.mainTitle,
            subTitle: item.subtitleTitle
          };
        });
        if (h5BannderList.length > 0) {
          this.h5BannerImgList = h5BannderList;
        } else {
          this.showH5DefaultBanner = true;
        }
    },
    gotoByUrl(url) {
      if (this.isHttpOrHttps(url)) {
        window.open(url);
      } else {
        // 如果是 /home 需要处理成 home
        const pathUrl = url.startsWith('/') ? url.slice(1) : url;
        this.goPath(pathUrl);
      }
    },
    isHttpOrHttps(url) {
      try {
        new URL(url);
        return true;
      } catch (err) {
        return false;
      }
    },
    bannerClick(banner_name, banner_position, banner_value) {
      PC_BannerClick(banner_name, banner_position, banner_value);
    },
    showQR(target, isSelf) {
      const domEle = this.$refs.qrCodeRef;
      this.qrOverSelf = isSelf;
      clearTimeout(this.qrTimer);
      if (isSelf) {
        domEle.style.left = this.qrLeft;
        domEle.style.top = this.qrTop;
      } else {
        if (target && target.getBoundingClientRect) {
          const rect = target.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
          const clientTop = document.documentElement.clientTop || 0;
          const clientLeft = document.documentElement.clientLeft || 0;
          const screentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          let left = rect.left + scrollLeft - clientLeft - 4;
          if (screentWidth > 1920) {
            left = left - (screentWidth - 1920) / 2 + 5;
          }
          const top = rect.top + scrollTop - clientTop - 72;
          this.qrLeft = left + 'px';
          this.qrTop = top + 'px';

          domEle.style.left = this.qrLeft;
          domEle.style.top = this.qrTop;
        }
      }
      domEle.style.display = 'block';
    },
    hideQR() {
      this.qrTimer = setTimeout(() => {
        this.$refs.qrCodeRef.style.display = 'none';
      }, 300);
    },
    arrowClick(val) {
      if (val === 'right') {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    goPath(path) {
      this.$router.push(this.$i18n.path(path));
    }
  },
  beforeDestroy() {
    this.$children.forEach(child => {
      child.$destroy();
      child = null;
    });
    if(this.$refs.cardShow){
      if(this.$refs.cardShow.$el) {
        this.$refs.cardShow.$el.innerHTML = '';
      }
      this.$refs.cardShow.$destroy();
      this.$refs.cardShow = null;
    }

  }
};
</script>
<style lang="less">
  .index-slogan-link{
    color: @yellow-P3;
    .medium-font();
    margin: 0 4px;
    pointer-events: none;
  }
</style>
<style lang="less" scoped>
img[src=''],
img:not([src]) {
  opacity: 0;
}
/*rtl:begin:ignore*/
html[dir='rtl'] {
  .link-btn-arrow {
    transform: rotateY(180deg);
  }
}
/*rtl:end:ignore*/


.qr-code-box {
  position: relative;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zh-carousel {
   height: 594px;
  .zh-main-wrap {
    min-width: 1200px;
    max-width: 1920px;

    // max-height: 736px;
    margin: auto;
    position: relative;

    .qr {
      position: absolute;
      // left: 295px;
      top: 340px;
      z-index: 10;
      color: @text-black;
      display: none;
      background: @black-popup-bg-new;
      transform: translateY(60px);
      border-radius: 12px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
      padding: 19px 34px;
      width: 180px;

      // 下载
      &.ko {
        //transform: translateY(43px);
        padding: 19px 17px;
      }

      &.download {
        box-sizing: border-box;
        //border-radius: 9px;

        .tit {
          font-size: 14px;
          .medium-font();
          font-weight: 500;
          color: @text-black;
          line-height: 18px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 13px;
          color: #fff;
        }

        .qr-code {
          width: 112px;
          height: 112px;
          background-color: #fff;
          transition: all 0.35s;
          border-radius: 10px;


          > img {
            position: relative;
            width: 22px;
            height: 22px;
          }
        }

        .img-container {
          width: 112px;
          height: 112px;
          margin: 16px auto;
        }

        .more-download {
          min-width: 84px;
          padding: 8px 12px;
          background: @yellow-P5;
          color: @yellow-P3;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          cursor: pointer;
        }

        .logo-qr {
          width: 22px;
          height: 22px;
          position: relative;
          top: -67px;
          left: 45px;
        }
      }
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 348px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 10;

      .icon-img {
        width: 40px;
        height: 40px;
      }

      .arrow-default-hover {
        display: none;
      }

      &:hover {
        .arrow-default {
          display: none;
        }

        .arrow-default-hover {
          display: block;
        }
      }
    }

    .left-arrow {
      left: -170px;
    }

    .right-arrow {
      right: -170px;
    }

    .item-wrap {
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      position: relative;
      height: 594px;

      .img-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .banner-img {
          position: absolute;
          width: 100%;
          height: auto;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .banner-text-row {
        width: 1200px;
        position: relative;
        // transform: translateY(50%);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 594px;
      }

      .left-wrap {
        width: 600px;
        position: relative;
        z-index: 999;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .banner-title {
          font-size: 48px;
          font-weight: bold;
          min-height: 56px;
          line-height: 56px;
          .bold-font();
          margin-bottom: 32px;
          color: #fff;
          &.orange {
            color: @yellow-P3;
          }
        }

        .banner-desc {
          font-size: 24px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 72px;
          color: @black-text-secondary;
          &.green {
            font-weight: 700;
            color: @green-color;
          }
        }
        .gift-main-wrap{
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 24px;
          cursor: pointer;
          .gift-img{
            margin-top: -2px;
            width: 24px;
            height: 24px;
          }
          .slogan-wrap{
            display: flex;
            align-items: center;
            font-size: 24px;
          }
        }
        .join-activity {
          // display: inline-block;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          font-size: 16px;
          color: @gray-P1;
          height: 22px;
          max-width: 220px;
          align-items: center;
          // padding-right: 20px;
          // background-image: url(~assets/img/common/icon_jiantou.svg);
          // background-position: right center;
          // background-repeat: no-repeat;
          // background-size: 16px 16px;

          &:hover {
            color: @yellow-P2-new;
            // background-image: url(~assets/img/common/icon_jiantou_hover.svg);
          }
          .link-btn-arrow{
            background-image: url(~assets/img/common/icon_jiantou.svg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
          }
          &:hover .link-btn-arrow {
            background-image: url(~assets/img/common/icon_jiantou_hover.svg);
          }
        }
        .home-advantage-new {
          .flex();
          margin-top: 60px;
          margin-bottom: 67px;

          .item {
            width: 150px;

            .img {
              width: 30px;
              height: 30px;
            }

            .title {
              margin-top: 12px;
              font-size: 14px;
              color: @gray-P1;
              line-height: 14px;
            }

            .sub-tit {
              width: 100px;
              margin-top: 7px;
              font-size: 12px;
              color: @gray-P1;
              line-height: 14px;
              white-space: pre-line;
            }
          }

          &.en {
            margin-top: 40px;

            .item {
              width: 200px;
            }

            .sub-tit {
              width: 150px;
            }
          }
        }
      }

      .right-main-wrap {
        cursor: pointer;
        position: relative;
        z-index: 10000;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        display: block;
        //right: -80px;
        // flex: 1;
        //width: 713px;
        height: 594px;

        .img-box {
          width: 713px;
        }
      }
    }
  }

  ::v-deep .el-carousel {
    .el-carousel__container{
      overflow-x: hidden;
    }

    .el-carousel__indicators {
      bottom: 17px;
    }

    .el-carousel__indicator {
      padding: 0 4px;

      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: @black-input-color;
        border-radius: 4px;
      }

      &.is-active {
        .el-carousel__button {
          width: 20px;
          background: @yellow-P3;
        }
      }
    }
  }
}

.white {
  &.zh-carousel {
    .zh-main-wrap {
      .left-wrap .banner-title,
      .qr.download .tit,
      .left-wrap .banner-desc,
      .left-wrap .join-activity {
        color: #000000;
      }
    }

    ::v-deep.el-carousel {
      .el-carousel__indicator {
        .el-carousel__button {
          background: @white-input-color;
        }

        &.is-active {
          .el-carousel__button {
            width: 20px;
            background: @yellow-P3;
          }
        }
      }
    }

    .zh-main-wrap .qr {
      background: @white-popup-bg;
    }
  }
}

@media screen and (max-width: 1600px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: -60px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: -60px;
  }
}

@media screen and (max-width: 1200px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: 20px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: 20px;
  }
}

@media screen and (min-width: 1600px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: -170px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: -170px;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1199px){
html[dir=rtl] {
  .left-wrap {
    margin-right: 40px;
  }
}
html:not([dir=rtl]) {
  .left-wrap {
    margin-left: 40px;
  }
}
.zh-carousel {
   height: 448px;
  .zh-main-wrap {
    min-width: 1020px;
    max-width: 1200px;

    // max-height: 736px;
    margin: auto;
    position: relative;

    .qr {
      position: absolute;
      left: 275px;
      top: 340px;
      z-index: 10;
      color: @text-black;
      display: none;
      background: @black-popup-bg-new;
      transform: translateY(60px);
      border-radius: 12px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
      padding: 19px 34px;

      // 下载
      &.ko {
        //transform: translateY(43px);
        padding: 19px 17px;
      }

      &.download {
        box-sizing: border-box;
        //border-radius: 9px;

        .tit {
          font-size: 14px;
          .medium-font();
          font-weight: 500;
          color: @text-black;
          line-height: 18px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 13px;
          color: #fff;
        }

        .qr-code {
          width: 112px;
          height: 112px;
          background-color: #fff;
          transition: all 0.35s;
          border-radius: 10px;

          > img {
            position: relative;
            width: 22px;
            height: 22px;
          }
        }

        .img-container {
          width: 112px;
          height: 112px;
          margin: 16px auto;
        }

        .more-download {
          min-width: 84px;
          padding: 8px 12px;
          background: @yellow-P5;
          color: @yellow-P3;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          cursor: pointer;
        }

        .logo-qr {
          width: 22px;
          height: 22px;
          position: relative;
          top: -67px;
          left: 45px;
        }
      }
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 348px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 10;

      .icon-img {
        width: 40px;
        height: 40px;
      }

      .arrow-default-hover {
        display: none;
      }

      &:hover {
        .arrow-default {
          display: none;
        }

        .arrow-default-hover {
          display: block;
        }
      }
    }

    .left-arrow {
      left: -170px;
    }

    .right-arrow {
      right: -170px;
    }

    .item-wrap {
      position: relative;
      height: 448px;

      .img-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .banner-img {
          position: absolute;
          width: 100%;
          height: auto;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .banner-text-row {
        width: 960px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 448px;
      }

      .left-wrap {
        width: 600px;
        position: relative;
        z-index: 999;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //margin-left: 40px;
        .banner-title {
          font-size: 40px;
          .bold-font();
          margin-bottom: 32px;
          color: #fff;
          &.orange {
            color: @yellow-P3;
          }
        }

        .banner-desc {
          font-size: 24px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 72px;
          color: @black-text-secondary;
          &.green {
            font-weight: 700;
            color: @green-color;
          }
        }

        .join-activity {
          // display: inline-block;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          font-size: 16px;
          color: @gray-P1;
          height: 22px;
          max-width: 200px;
          align-items: center;
          // padding-right: 20px;
          // background-image: url(~assets/img/common/icon_jiantou.svg);
          // background-position: right center;
          // background-repeat: no-repeat;
          // background-size: 16px 16px;

          &:hover {
            color: @yellow-P2-new;
            background-image: url(~assets/img/common/icon_jiantou_hover.svg);
          }
          .link-btn-arrow{
            background-image: url(~assets/img/common/icon_jiantou.svg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
          }
          &:hover .link-btn-arrow {
            background-image: url(~assets/img/common/icon_jiantou_hover.svg);
          }
        }

        .home-advantage-new {
          .flex();
          margin-top: 60px;
          margin-bottom: 67px;

          .item {
            width: 150px;

            .img {
              width: 30px;
              height: 30px;
            }

            .title {
              margin-top: 12px;
              font-size: 14px;
              color: @gray-P1;
              line-height: 14px;
            }

            .sub-tit {
              width: 100px;
              margin-top: 7px;
              font-size: 12px;
              color: @gray-P1;
              line-height: 14px;
              white-space: pre-line;
            }
          }

          &.en {
            margin-top: 40px;

            .item {
              width: 200px;
            }

            .sub-tit {
              width: 150px;
            }
          }
        }
      }

      .right-main-wrap {
        cursor: pointer;
        position: relative;
        z-index: 10000;
        position: absolute;
        // right: 0;
        top: 0;
        width: 50%;
        display: block;
        //right: -80px;
        // flex: 1;
        //width: 713px;
        height: 448px;

        .img-box {
          width: 713px;
        }
      }
    }
  }

  ::v-deep .el-carousel {
    .el-carousel__container{
      overflow-x: hidden;
    }

    .el-carousel__indicators {
      bottom: 17px;
    }

    .el-carousel__indicator {
      padding: 0 4px;

      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: @black-input-color;
        border-radius: 4px;
      }

      &.is-active {
        .el-carousel__button {
          width: 20px;
          background: @yellow-P3;
        }
      }
    }
  }
}
}
.introduce{
  display: none;
}
@media screen and (max-width: 1019px) {
  .zh-carousel{
   display: none;
  }
  .zh-carousel .zh-main-wrap .item-wrap .left-wrap {
    width: 380px;
    margin: -40px 0 0 10px;
  }
  .introduce {
    display: block;
    background: @black-bg-primary-V2;
    border: 1px solid @black-bg-primary-V2;
    width: 100%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 64px;
    .introduce_cont {
      position: relative;
      width: 92vw;
      margin: 0 auto;

      .introduce_top {
        width: 100%;
        min-height: 75vw;

        img {
          display: block;
          width: 100%;
        }
      }

      .introduce_bottom {
        width: 100%;

        .title {
          font-size: 28px;
          .bold-font();
          text-align: center;
          white-space: pre-line;
          color: @black-text-primary-V2;
        }

        .title2 {
          margin-top: 24px;
          font-size: 18px;
          text-align: center;
          color: @black-text-tertiary-V2;
        }

        .sub-tit {
          margin-top: 2.75rem;
          //height: 3.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.3rem;
          text-align: center;
          color: @black-text-tertiary-V2;
        }
      }
    }

    .h5-gift-main-wrap {
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-radius: 12px;
        margin-bottom: 16px;
        border: 1px solid @black-border-heavy-V2;
        margin-top: 44px;
        padding: 10px;
        color: #fff;
        .gift-img{
          width: 24px;
          height: 24px;
        }
    }
    .login_register_box {

      .new_Register_btn {
        background-color: @black-btn-primary-default-V2;
        color: @always-black !important;
        font-size: 16px;
        .medium-font();
        border-radius: 12px;
        &:hover{
          background-color: @black-btn-primary-hover-V2;
        }
      }

      .btn_buy_wxt {
        display: flex;
        justify-content: center;
        background: @black-bg-input-V2;
        margin-top: 16px;
        &:hover{
          background: @black-bg-input-hover-V2;
        }
        .weex-logo {
          width: 48px;
          height: 48px;
          // margin-left: -12px;
        }
      }

      .new_Register_btn,
      .btn_buy_wxt {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        color: @black-text-primary-V2;
        .medium-font();
      }
    }
  }
}
</style>

